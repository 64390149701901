import React from 'react'
import styled from 'styled-components'
import Loadable from '@loadable/component'

import SEO from '@/components/seo'
import CalculatorStructure from '@/components/CalculatorStructure'

import { CALCULATORS } from '@/helpers/mocks/calculators'
import SectionInfoCard from './SectionInfoCard'

const Derivative = Loadable(() => import('@/components/Calculator/Math/Derivative'))

const StyledDerivativeCalculator = styled(Derivative)`
  min-width: unset;
  max-width: unset;
`

const Ebay = () => (
  <>
    <SEO title="Derivative" />
    <CalculatorStructure
      section="math"
      headerTitle="math"
      title={CALCULATORS.math.derivative.title}
      description={CALCULATORS.math.derivative.description}
      calculatorNode={<StyledDerivativeCalculator />}
      asideCards={<SectionInfoCard />}
    />
  </>
)

export default Ebay
